const jwt_claims = {
    title: 'Пользовательский JWT',
    description: 'Настройте пользовательские утверждения JWT для включения в токен доступа. Эти утверждения могут использоваться для передачи дополнительной информации в ваше приложение.',
    user_jwt: {
        card_title: 'Для пользователя',
        card_field: 'Токен доступа пользователя',
        card_description: 'Добавление специфичных для пользователя данных во время выдачи токена доступа.',
        for: 'для пользователя',
    },
    machine_to_machine_jwt: {
        card_title: 'Для М2М',
        card_field: 'Токен от машины к машине',
        card_description: 'Добавление дополнительных данных во время выдачи токена от машины к машине.',
        for: 'для М2М',
    },
    code_editor_title: 'Настройка своих утверждений {{token}}',
    custom_jwt_create_button: 'Добавить пользовательские утверждения',
    custom_jwt_item: 'Пользовательские утверждения {{for}}',
    delete_modal_title: 'Удалить пользовательские утверждения',
    delete_modal_content: 'Вы уверены, что хотите удалить пользовательские утверждения?',
    clear: 'Очистить',
    cleared: 'Очищено',
    restore: 'Восстановить по умолчанию',
    restored: 'Восстановлено',
    data_source_tab: 'Источник данных',
    test_tab: 'Тестовый контекст',
    jwt_claims_description: 'Утверждения по умолчанию автоматически включаются в JWT и не могут быть переопределены.',
    user_data: {
        title: 'Данные пользователя',
        subtitle: 'Используйте параметр ввода `context.user` для предоставления важной информации о пользователе.',
    },
    grant_data: {
        title: 'Данные предоставления',
        subtitle: 'Используйте параметр ввода `context.grant` для предоставления важной информации о предоставлении, доступной только для обмена токенами.',
    },
    token_data: {
        title: 'Данные токена',
        subtitle: 'Используйте параметр ввода `token` для полезной нагрузки текущего токена доступа. ',
    },
    api_context: {
        title: 'Контекст API: управление доступом',
        subtitle: 'Используйте метод `api.denyAccess` для отклонения запроса токена.',
    },
    fetch_external_data: {
        title: 'Получение внешних данных',
        subtitle: 'Интегрирование данных непосредственно из ваших внешних API в утверждения.',
        description: 'Используйте функцию `fetch` для вызова ваших внешних API и включения данных в ваши пользовательские утверждения. Пример: ',
    },
    environment_variables: {
        title: 'Установка переменных среды',
        subtitle: 'Используйте переменные среды для хранения конфиденциальной информации.',
        input_field_title: 'Добавить переменные среды',
        sample_code: 'Доступ к переменным среды в вашем обработчике утверждений JWT. Пример: ',
    },
    jwt_claims_hint: 'Ограничьте пользовательские утверждения до 50 КБ. Утверждения по умолчанию JWT автоматически включаются в токен и не могут быть переопределены.',
    tester: {
        subtitle: 'Настройте макетный токен и данные пользователя для тестирования.',
        run_button: 'Запустить тест',
        result_title: 'Результат теста',
    },
    form_error: {
        invalid_json: 'Недопустимый формат JSON',
    },
};
export default Object.freeze(jwt_claims);
